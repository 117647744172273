var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.value,
        width: "500",
        "hide-overlay": "",
        persistent: "",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-title",
            [
              _c("v-icon", { attrs: { left: "", color: "black" } }, [
                _vm._v("mdi-camera-control"),
              ]),
              _vm._v(" Chart Controls "),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "black--text" },
            [
              _c(
                "v-list",
                _vm._l(_vm.instructions, function (instruction, idx) {
                  return _c(
                    "v-list-item",
                    { key: idx },
                    [
                      _c("v-list-item-content", [
                        _c(
                          "span",
                          { staticClass: "font-weight-bold" },
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: { left: "", color: "black", small: "" },
                              },
                              [_vm._v(_vm._s(instruction.icon))]
                            ),
                            _vm._v(" " + _vm._s(instruction.label) + " "),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ml-7",
                            staticStyle: { "max-width": "350px" },
                          },
                          [_vm._v(" " + _vm._s(instruction.description) + " ")]
                        ),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text-capitalize",
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("update:value", false)
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }