<template>
  <v-dialog :value="value" width="500" hide-overlay persistent>
    <v-card outlined>
      <v-card-title>
        <v-icon left color="black">mdi-camera-control</v-icon>
        Chart Controls
      </v-card-title>
      <v-card-text class="black--text">
        <v-list>
          <v-list-item v-for="(instruction, idx) in instructions" :key="idx">
            <v-list-item-content>
              <span class="font-weight-bold">
                <v-icon left color="black" small>{{ instruction.icon }}</v-icon>
                {{ instruction.label }}
              </span>
              <div class="ml-7" style="max-width: 350px">
                {{ instruction.description }}
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          class="text-capitalize"
          @click="$emit('update:value', false)">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ChartInfo',
  props: {
    value: Boolean
  },
  computed: {
    instructions() {
      return [
        {
          icon: 'mdi-plus-minus-variant',
          label: 'Zoom',
          description:
            'To zoom in and out of the chart using your mouse or trackpad, simply use the scroll wheel on your mouse or pinch with two fingers on your trackpad.'
        },
        {
          icon: 'mdi-selection-drag',
          label: 'Select',
          description:
            'To select a specific date range on the chart, click and drag your mouse over the area you want to focus on. This will create a selection box that will zoom in on the selected area.'
        },
        {
          icon: 'mdi-arrow-left-right',
          label: 'Pan',
          description:
            'To pan the chart, simply hold down the Shift key on your keyboard and click and drag your mouse in any direction. This will allow you to move the chart left or right without changing the zoom level.'
        }
      ]
    }
  }
}
</script>
